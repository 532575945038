import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from "@angular/common/http";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { SkillsService } from "./services/skills.service";
import { CompaniesService } from "./services/companies.service";
import { LanguagesService } from "./services/languages.service";
import { ProjectsService } from "./services/projects.service";
import { SoftskillsService } from "./services/softskills.service";
import { StudiesService } from "./services/studies.service";
import { WorkPointsService } from "./services/work-points.service";

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule
  ],
  providers: [
    SkillsService,
    CompaniesService,
    LanguagesService,
    ProjectsService,
    SoftskillsService,
    StudiesService,
    WorkPointsService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
