import { Component, OnInit } from '@angular/core';

import { SkillsService } from './services/skills.service';
import { CompaniesService } from "./services/companies.service";
import { LanguagesService } from "./services/languages.service";
import { ProjectsService } from "./services/projects.service";
import { SoftskillsService } from "./services/softskills.service";
import { StudiesService } from "./services/studies.service";
import { WorkPointsService } from "./services/work-points.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'website-app';
  skills = [];
  companies = [];
  languages = [];
  projects = [];
  softSkills = [];
  studies = [];
  workPoints = [];

  constructor(private skillsService: SkillsService,
              private companiesService: CompaniesService,
              private projectsService: ProjectsService,
              private softskillsService: SoftskillsService,
              private studiesService: StudiesService,
              private workPointsService: WorkPointsService,
              private languagesService: LanguagesService) {}

  ngOnInit() {
    this.getSkills();
    this.getCompanies();
    this.getLanguages();
    this.getProjects();
    this.getSoftSkills();
    this.getStudies();
    this.getWorkPoints();
  }

  // Read all skills
  getSkills(): void {
    this.skillsService.getAll()
      .subscribe(
        restItems => {
          this.skills = restItems;
          console.log(this.skills);
        }
      )
  }

  // Read all companies
  getCompanies(): void {
    this.companiesService.getAll()
      .subscribe(
        restItems => {
          this.companies = restItems;
          console.log(this.companies);
        }
      )
  }

  // Read all languages
  getLanguages(): void {
    this.languagesService.getAll()
      .subscribe(
        restItems => {
          this.languages = restItems;
          console.log(this.languages);
        }
      )
  }

  // Read all languages
  getProjects(): void {
    this.projectsService.getAll()
      .subscribe(
        restItems => {
          this.projects = restItems;
          console.log(this.projects);
        }
      )
  }

  // Read all languages
  getSoftSkills(): void {
    this.softskillsService.getAll()
      .subscribe(
        restItems => {
          this.softSkills = restItems;
          console.log(this.softSkills);
        }
      )
  }

  // Read all languages
  getStudies(): void {
    this.studiesService.getAll()
      .subscribe(
        restItems => {
          this.studies = restItems;
          console.log(this.studies);
        }
      )
  }

  // Read all languages
  getWorkPoints(): void {
    this.workPointsService.getAll()
      .subscribe(
        restItems => {
          this.workPoints = restItems;
          console.log(this.workPoints);
        }
      )
  }
}
